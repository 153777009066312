import { IUser, UserStatus, UserType } from "../types/resources";

export function isInactive(user: IUser): boolean {
  return (user.archived || !user.enabled);
}

export function type(user: IUser): UserType {
  return user.userIsAdministrator ? 'Admin' : 'User';
}

export function status(user: IUser): UserStatus {
  if (user.archived) {
    return 'Archived'; }
  else if (!user.enabled) {
    return 'Disabled by Provisioning';
  } else {
    return 'Active';
  }
}

export function canBeInvited(user: IUser): boolean {
  return !isInactive(user) && !hasActiveAssessment(user);
}

export function hasActiveAssessment(user: IUser): boolean {
  return !!user.activeSurveyResponse;
}

export function hasValidEmail(user: IUser): boolean {
  return user.emailDeliveryStatus !== 'hard_bouncing';
}
