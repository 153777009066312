import React, { useContext, FunctionComponent, ReactElement } from "react";
import { ThemeContext } from "styled-components";
import { useFetchUserListApi } from "../../../utils/api/options/users";
import { useApiRequest, useFilterCallbacks, usePaginationCallbacks, useSortByCallbacks } from "../../../utils/api/hooks";
import { canBeInvited, hasValidEmail, isInactive } from "../../../utils/user";
import MiniActionButton from "../../../common/Buttons/MiniActionButton";
import { IUser } from "../../../types/resources";
import { Table, Thead, THr, Tr, TBody } from "../../../common/DataTable/DataTable";
import {
  ActionsCell,
  AssessmentCell,
  CheckboxCell,
  CheckboxHeaderCell,
  TextHeaderCell,
  TextCell,
  MultilineTextCell,
} from "../../../common/DataTable/Cells";
import AddNewUserModal from "../modals/AddNewUserModal";
import EditUserModal from "../modals/EditUserModal";
import ExportUsersModal from "../modals/ExportUsersModal";
import InviteUsersModal from "../modals/InviteUsersModal";
import {
  useFetchSurveyTemplateListings,
  useFetchUserDepartmentListings,
  useFetchUserLocationListings
} from '../../../utils/filterHelpers';
import { useUserListFilters } from '../../../utils/table/filters/userList';
import { usePageFetchWithFilters } from '../../../utils/dashboard/filters';
import Pagination from "../../../common/Pagination/Pagination";
import UserCards from "./UserCards";
import Box from "../../../common/Box/Box";
import { useModal } from "../../../common/Modal/hooks";
import StyledCardsAndPagination from "../common/StyledCardsAndPagination";
import UserFilters from "./UserFilters";
import useSelect from "../../../common/Forms/useSelect";
import { EditUserModalStep } from "../modals/EditUserModal/types";
import { archivedUserTooltip, invalidEmailTooltip } from "../../../common/Tooltip/utils";

const UsersList: FunctionComponent = (): ReactElement => {
  const { TABLE } = useContext(ThemeContext);
  const fetchUserListApi = useFetchUserListApi();
  const { close: closeAddNewUserModal, open: openAddNewUserModal, isOpen: isAddNewUserModalOpen } = useModal();
  const { close: closeExportUsersModal, open: openExportUsersModal, isOpen: isExportUsersModalOpen } = useModal<IUser[]>();
  const { close: closeInviteUsersModal, data: inviteUsersModalData, open: openInviteUsersModal, isOpen: isInviteUsersModalOpen } = useModal<IUser[]>();
  const {
    close: closeEditUserModal,
    data: editUserModalData,
    open: openEditUserModal,
    initialStep: initialEditUserModalStep,
    isOpen: isEditUserModalOpen,
  } = useModal<IUser, EditUserModalStep>();

  const {
    fetch: fetchUsers,
    data: users,
    _filters,
    _pagination,
    _sortBy,
  } = useApiRequest<never, IUser[]>(fetchUserListApi);
  const {
    select: selectUser,
    selectAll: selectAllUsers,
    selected: selectedUsers,
    areAllItemsSelected: areAllUsersSelected,
    isSelected: isUserSelected,
  } = useSelect(users);

  const { setFilters, clearFilters } = useFilterCallbacks(_filters, fetchUsers, _pagination);
  usePageFetchWithFilters(setFilters, clearFilters);

  const { surveyTemplates } = useFetchSurveyTemplateListings();
  const { userDepartments } = useFetchUserDepartmentListings();
  const { userLocations } = useFetchUserLocationListings();

  const filterConfig = useUserListFilters(userDepartments, userLocations, surveyTemplates, ['1', '3', '7', '14', '30']);

  const { currentPage, totalItemCount, itemsPerPage } = _pagination.current;
  const paginationCallbacks = usePaginationCallbacks(_pagination, fetchUsers);
  const { sortBy } = useSortByCallbacks(_sortBy, fetchUsers, _pagination);

  const handleUsersUpdate = () => {
    closeAddNewUserModal();
    closeEditUserModal();
    closeInviteUsersModal();
    fetchUsers();
  };

  return (
    <>
      <UserFilters config={filterConfig} onChange={setFilters} onClear={clearFilters} filters={_filters} />
      <StyledCardsAndPagination>
        <UserCards 
          selectedUsers={selectedUsers || []} 
          onOpenExportModal={() => openExportUsersModal(selectedUsers)}
          onOpenInviteModal={() => openInviteUsersModal(selectedUsers)}
          onOpenNewUserModal={openAddNewUserModal}
        />
        <Pagination
          itemName="user"
          currentPage={currentPage}
          total={totalItemCount || 0}
          itemsPerPage={itemsPerPage}
          onPageChange={paginationCallbacks.goToPage}
          select
          onFilterChange={paginationCallbacks.setItemsPerPage}
        />
      </StyledCardsAndPagination>
      <Box noPadding shadow>
        <Table>
          <Thead>
            <THr>
              <CheckboxHeaderCell id='all-users' value={areAllUsersSelected} onChange={selectAllUsers} />
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='firstName' onSort={sortBy}>First Name</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='lastName' onSort={sortBy}>Last Name</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='emailAddress' onSort={sortBy}>Email</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='location' onSort={sortBy}>Location</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='department' onSort={sortBy}>Department</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='jobTitle' onSort={sortBy}>Job title</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='activeSurveyResponseCreatedAt' onSort={sortBy} align="center">Active assessment</TextHeaderCell>
              <TextHeaderCell align="center">Last assessment</TextHeaderCell>
              <TextHeaderCell align="center">Actions</TextHeaderCell>
            </THr>
          </Thead>
          <TBody>
            { users && users.map(user => {
              const isEmailValid = hasValidEmail(user);
              const isSelected = isUserSelected(user.id);

              return (
                <Tr key={user.id}>
                  <CheckboxCell id={user.id} value={isSelected} onChange={selectUser} />
                  <TextCell
                    icon={isInactive(user) ? 'padlock' : undefined}
                    iconColor={TABLE.PADLOCK}
                    iconTooltip={archivedUserTooltip}
                    text={user.firstName}
                  />
                  <TextCell text={user.lastName} />
                  <TextCell
                    id={user.id}
                    text={user.emailAddress}
                    icon={isEmailValid ? undefined : 'warning'}
                    iconColor={TABLE.WARNING}
                    iconTooltip={invalidEmailTooltip}
                    color={isEmailValid ? undefined : TABLE.WARNING}
                    onClick={isEmailValid ? undefined : () => openEditUserModal(user, 'edit')}
                  />
                  <MultilineTextCell text={user.location} lines={2} />
                  <MultilineTextCell text={user.department} lines={2} />
                  <MultilineTextCell text={user.jobTitle} lines={2} />
                  <AssessmentCell id={user.activeSurveyResponse?.id} name={user.activeSurveyResponse?.surveyTemplate?.name} date={user.activeSurveyResponse?.createdAt} center />
                  <AssessmentCell id={user.lastCompletedSurveyResponse?.id} name={user.lastCompletedSurveyResponse?.surveyTemplate?.name} date={user.lastCompletedSurveyResponse?.finishedAt} center />
                  <ActionsCell>
                    <MiniActionButton
                      id={user.id}
                      disabled={!canBeInvited(user)}
                      name="userAdd"
                      tooltip="Invite user"
                      onClick={() => openInviteUsersModal([user])}
                    />
                    <MiniActionButton id={user.id} name="userEdit" tooltip="Edit user" onClick={() => openEditUserModal(user)} />
                  </ActionsCell>
                </Tr>
              );
            }) }
          </TBody>
        </Table>
      </Box>
      { inviteUsersModalData && (
        <InviteUsersModal
          users={inviteUsersModalData}
          onCancel={closeInviteUsersModal}
          onOk={handleUsersUpdate}
          isOpen={isInviteUsersModalOpen}
        />
      )}
      { editUserModalData && (
        <EditUserModal
          initialStep={initialEditUserModalStep}
          user={editUserModalData}
          onCancel={closeEditUserModal}
          onOk={handleUsersUpdate}
          isOpen={isEditUserModalOpen}
        />
      )}
      <ExportUsersModal
        isOpen={isExportUsersModalOpen}
        onCancel={closeExportUsersModal}
        selectedUsers={selectedUsers || []}
        pageUsers={users || []}
        allUsersCount={_pagination.current.totalItemCount || 0}
      />
      <AddNewUserModal isOpen={isAddNewUserModalOpen} onCancel={handleUsersUpdate} onOk={handleUsersUpdate} />
    </>
  );
};

export default UsersList;
