import React, { FunctionComponent, ReactElement, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import LinkButton from "../../../../common/Buttons/LinkButton";
import Card from "../../../../common/Card/Card";
import Icon from "../../../../common/Icons/Icon";
import { UserCardProps } from "../types";
import { StyledCardContent, StyledEntry, StyledEntryLabel, StyledEntryValue, StyledRow, StyledTitle } from "./StyledAssessementCard";

const StyledIcon = styled.div`
  margin-right: 8px;
`;

const UserCard: FunctionComponent<UserCardProps> = ({ onOpenEditUserModal, assessmentDetails }): ReactElement => {
  const { user } = assessmentDetails
  const { COLORS } = useContext(ThemeContext)
  const userName = `${user.firstName} ${user.lastName}`;
  const location = user.location ?? '—';
  const jobTitle = user.jobTitle ?? '—';
  const department = user.department ?? '—';

  return (
    <Card width="34%">
      <StyledCardContent>
        <StyledRow>
          <StyledIcon>
            <Icon name="userCircle" size={20} color={COLORS.GREEN}/>
          </StyledIcon>
          <StyledTitle>{userName}</StyledTitle>
          <LinkButton icon="pencil" label="History" onClick={() => onOpenEditUserModal(assessmentDetails.user)} />
        </StyledRow>
        <StyledRow padding="0 26px">
          <StyledEntry maxWidth="34%">
            <StyledEntryLabel>Location</StyledEntryLabel>
            <StyledEntryValue tooltip={location}>{location}</StyledEntryValue>
          </StyledEntry>
          <StyledEntry maxWidth="33%">
            <StyledEntryLabel>Job title</StyledEntryLabel>
            <StyledEntryValue tooltip={jobTitle}>{jobTitle}</StyledEntryValue>
          </StyledEntry>
          <StyledEntry maxWidth="33%">
            <StyledEntryLabel>Department</StyledEntryLabel>
            <StyledEntryValue tooltip={department}>{department}</StyledEntryValue>
          </StyledEntry>
        </StyledRow>
      </StyledCardContent>
    </Card>
  );
};

export default UserCard;
