import { IQuestionAnswerData, ISurveyResponse } from "../types/resources";
import { isInactive } from "./user";

const isActiveAssessment = (assessment : ISurveyResponse): boolean => {
  switch(assessment.status) {
    case 'not_started':
    case 'in_progress':
      return true;
    case 'cancelled':
    case 'cancelled_by_user_archival':
    case 'finished':
      return false;
  }
}

const isCompletedAssessment = (assessment : ISurveyResponse): boolean => {
  switch(assessment.status) {
    case 'finished':
      return true;
    case 'cancelled':
    case 'not_started':
    case 'in_progress':
    case 'cancelled_by_user_archival':
      return false;
  }
}

export function canBeReminded(assessment: ISurveyResponse) {
  return isActiveAssessment(assessment) && !isInactive(assessment.user);
}

export const canBeCancelled = canBeReminded;

export function canFollowUp(assessment: ISurveyResponse) {
  return isCompletedAssessment(assessment) && !isInactive(assessment.user);
}

export function canBeContacted(assessment: ISurveyResponse) {
  return !isInactive(assessment.user);
}

export const getAnswerValue = (data: IQuestionAnswerData) => {
  switch(data.status) {
    case 'actioned':
    case 'unanswered':
    case 'skipped_by_branching':
      return ''
    case 'answered':
      return data.value;
    default:
      throw new Error(`Unhandled answer status: ${data}`)
  }
}
