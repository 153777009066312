import React, { FunctionComponent, ReactElement, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { ThemeContext } from "styled-components";
import Box from "../../../common/Box/Box";
import MiniActionButton from "../../../common/Buttons/MiniActionButton";
import { ActionsCell, AssessmentCell, AssessmentStatusCell, CheckboxCell, CheckboxHeaderCell, DateCell, RiskCell, TextCell, TextHeaderCell } from "../../../common/DataTable/Cells";
import { Table, TBody, Thead, THr, Tr } from "../../../common/DataTable/DataTable";
import Pagination from "../../../common/Pagination/Pagination";
import { ISurveyResponse } from "../../../types/resources";
import { useApiRequest, useFilterCallbacks, usePaginationCallbacks, useSortByCallbacks } from "../../../utils/api/hooks";
import { useFetchAssessmentListApi } from "../../../utils/api/options/assessments";
import { usePageFetchWithFilters } from "../../../utils/dashboard/filters";
import { getManualAssessmentStatus } from "../../../utils/surveyResponse";
import { getAssessmentDetailsRoute } from "../../../routing/AdminRouter";
import ContactModal from "../modals/ContactModal";
import FollowUpModal from "../modals/FollowUpModal";
import CancelAssessmentsModal from "../modals/CancelAssessmentsModal";
import ExportAssessmentsModal from "../modals/ExportAssessmentsModal";
import SendReminderModal from "../modals/SendReminderModal";
import { useModal } from "../../../common/Modal/hooks";
import { canFollowUp, canBeReminded, canBeCancelled, canBeContacted } from "../../../utils/assessment";
import AssessmentFilters from "./AssessmentFilters";
import { useAssessmentListAutoFilters, useAssessmentListFilters } from "../../../utils/table/filters/assessmentList";
import { useFetchSurveyTemplateListings, useFetchUserDepartmentListings, useFetchUserLocationListings } from "../../../utils/filterHelpers";
import StyledCardsAndPagination from "../common/StyledCardsAndPagination";
import AssessmentCards from "./AssessmentCards";
import useSelect from "../../../common/Forms/useSelect";
import { isInactive } from "../../../utils/user";
import { archivedUserTooltip } from "../../../common/Tooltip/utils";

const AssessmentsList: FunctionComponent = (): ReactElement => {
  const fetchAssessmentListApi = useFetchAssessmentListApi();
  const history = useHistory();
  const { TABLE } = useContext(ThemeContext);
  const { close: closeCancelAssessmentsModal, data: cancelAssessmentsModalData, isOpen: isCancelAssessmentsModalOpen, open: openCancelAssessmentsModal } = useModal<ISurveyResponse[]>();
  const { close: closeContactModal, data: contactModalData, isOpen: isContactModalOpen, open: openContactModal } = useModal<ISurveyResponse[]>();
  const { close: closeExportAssessmentsModal, data: exportAssessmentsModalData, isOpen: isExportAssessmentsModalOpen, open: openExportAssessmentsModal } = useModal<ISurveyResponse[]>();
  const { close: closeFollowUpModal, data: followUpModalData, isOpen: isFollowUpModalOpen, open: openFollowUpModal } = useModal<ISurveyResponse>();
  const { close: closeSendReminderModal, data: sendReminderModalData, isOpen: isSendReminderModalOpen, open: openSendReminderModal } = useModal<ISurveyResponse[]>();

  const {
    fetch: fetchAssessments,
    data: assessments,
    _filters,
    _pagination,
    _sortBy
  } = useApiRequest<never, ISurveyResponse[]>(fetchAssessmentListApi);
  const {
    select: selectAssessment,
    selectAll: selectAllAssessments,
    selected: selectedAssessments,
    areAllItemsSelected: areAllAssessmentsSelected,
    isSelected: isAssessmentSelected,
  } = useSelect(assessments);

  const { setFilters, clearFilters } = useFilterCallbacks(_filters, fetchAssessments, _pagination);
  usePageFetchWithFilters(setFilters, clearFilters);

  const paginationCallbacks = usePaginationCallbacks(_pagination, fetchAssessments);
  const { sortBy } = useSortByCallbacks(_sortBy, fetchAssessments, _pagination);
  const { currentPage, totalItemCount, itemsPerPage } = _pagination.current;

  const { userDepartments } = useFetchUserDepartmentListings();
  const { userLocations } = useFetchUserLocationListings();
  const { surveyTemplates } = useFetchSurveyTemplateListings();
  const filterConfig = useAssessmentListFilters(userDepartments, userLocations, surveyTemplates);
  const filterAutoConfig = useAssessmentListAutoFilters(surveyTemplates);

  const handleAssessmentsUpdate = () => {
    fetchAssessments();
    closeCancelAssessmentsModal();
    closeContactModal();
    closeExportAssessmentsModal();
    closeFollowUpModal();
    closeSendReminderModal();
  };

  return (
    <div>
      <AssessmentFilters
        config={filterConfig}
        quickFiltersConfig={filterAutoConfig}
        filters={_filters}
        onChange={setFilters}
        onClear={clearFilters}
      />
      <StyledCardsAndPagination>
        <AssessmentCards 
          selectedAssessments={selectedAssessments || []}
          onOpenCancelAssessmentsModal={() => openCancelAssessmentsModal(selectedAssessments)}
          onOpenContactModal={() => openContactModal(selectedAssessments)}
          onOpenExportAssessmentsModal={() => openExportAssessmentsModal(selectedAssessments)}
          onOpenSendReminderModal={() => openSendReminderModal(selectedAssessments)}
        />
        <Pagination
          itemName="assessment"
          currentPage={currentPage}
          total={totalItemCount || 0}
          itemsPerPage={itemsPerPage}
          onPageChange={paginationCallbacks.goToPage}
          select
          onFilterChange={paginationCallbacks.setItemsPerPage}
        />
      </StyledCardsAndPagination>
      <Box noPadding shadow>
        <Table>
          <Thead>
            <THr>
              <CheckboxHeaderCell id='all-assessments' value={areAllAssessmentsSelected} onChange={selectAllAssessments} />
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='suggestedPriority' onSort={sortBy}>Priority</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='userFirstName' onSort={sortBy}>First Name</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='userLastName' onSort={sortBy}>Last Name</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='currentRiskFactor' onSort={sortBy} align="center">Current risk</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='surveyTemplateName' onSort={sortBy}>Assessment type</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='status' onSort={sortBy}>Assessment status</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='createdAt' onSort={sortBy}>Invited on</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='manualAssessmentStatus' onSort={sortBy}>Follow-up status</TextHeaderCell>
              <TextHeaderCell align="center">Actions</TextHeaderCell>
            </THr>
          </Thead>
          <TBody>
            { assessments && assessments.map(assessment => {

              const {
                createdAt, finishedAt, id, status, suggestedPriority, user,
              } = assessment
              const { firstName, lastName } = user
              const followUpStatus = getManualAssessmentStatus(assessment.manualAssessment?.status)
              const isSelected = isAssessmentSelected(id);

              return (
                <Tr key={id}>
                  <CheckboxCell id={id} value={isSelected} onChange={selectAssessment} />
                  <TextCell text={suggestedPriority?.toString() || '—'} />
                  <TextCell
                    text={firstName}
                    icon={isInactive(user) ? 'padlock' : undefined}
                    iconColor={TABLE.PADLOCK}
                    iconTooltip={archivedUserTooltip}
                  />
                  <TextCell text={lastName} />
                  <RiskCell value={assessment.meta.riskCategory.data.id} />
                  <AssessmentCell name={assessment.surveyTemplate.name} />
                  <AssessmentStatusCell status={status} date={finishedAt} />
                  <DateCell date={createdAt} />
                  <TextCell text={followUpStatus} />
                  <ActionsCell>
                    <MiniActionButton id={id} name="eye" tooltip="Show" onClick={() => history.push(getAssessmentDetailsRoute(id))} />
                    <MiniActionButton
                      id={id}
                      disabled={!canBeReminded(assessment)}
                      name="bell"
                      tooltip="Remind"
                      onClick={() => openSendReminderModal([assessment])}
                      />
                    <MiniActionButton
                      id={id}
                      disabled={!canFollowUp(assessment)}
                      name="updateFile"
                      tooltip="Update follow-up status"
                      onClick={() => openFollowUpModal(assessment)}
                    />
                    <MiniActionButton 
                      id={id} 
                      disabled={!canBeContacted(assessment)}
                      name="envelope"
                      tooltip="Contact"
                      onClick={() => openContactModal([assessment])} />
                    <MiniActionButton
                      id={id}
                      disabled={!canBeCancelled(assessment)}
                      name="cancel"
                      tooltip="Cancel assessment"
                      onClick={() => openCancelAssessmentsModal([assessment])}
                    />
                  </ActionsCell>
                </Tr>
              );
            }) }
          </TBody>
        </Table>
      </Box>
      {contactModalData && (
        <ContactModal
          isOpen={isContactModalOpen}
          onCancel={closeContactModal}
          items={contactModalData}
          itemType="surveyResponse"
          onOk={closeContactModal}
        />
      )}
      {followUpModalData && (
        <FollowUpModal
          isOpen={isFollowUpModalOpen}
          onCancel={closeFollowUpModal}
          onOk={handleAssessmentsUpdate}
          assessment={followUpModalData}
        />
      )}
      {cancelAssessmentsModalData && (
        <CancelAssessmentsModal
          isOpen={isCancelAssessmentsModalOpen}
          onCancel={closeCancelAssessmentsModal}
          onOk={handleAssessmentsUpdate}
          assessments={cancelAssessmentsModalData}
        />
      )}
      {exportAssessmentsModalData && (
        <ExportAssessmentsModal 
          isOpen={isExportAssessmentsModalOpen}
          onCancel={closeExportAssessmentsModal}
          selectedAssessments={exportAssessmentsModalData}
          pageAssessments={assessments || []}
          allAssessmentsCount={_pagination.current.totalItemCount || 0}
        />
      )}
      {sendReminderModalData && <SendReminderModal isOpen={isSendReminderModalOpen} onCancel={closeSendReminderModal} assessments={sendReminderModalData} />}
    </div>
  );
};

export default AssessmentsList;
