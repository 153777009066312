import React, { FunctionComponent, ReactElement } from "react";
import Select from "../Forms/Select";
import { SelectFilterProps } from "./types";
import { optionToFilterValue } from "./utils";

const UserAgeFilter: FunctionComponent<SelectFilterProps<'minimumUserAge'>> = ({ filterKey, value, options, onChange }): ReactElement => {
  return (
    <Select
      value={value}
      label="User creation date"
      options={options}
      placeholder="Users older than"
      onChange={(selectedOption) => onChange(optionToFilterValue(selectedOption, filterKey, false))}
      isClearable
    />
  );
};

export default UserAgeFilter;
