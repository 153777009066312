import { useMemo } from "react";
import { IFilterConfig, IAutoFilterConfig } from "../../../common/Table/Context/TableFilterConfigContext";
import { getCheckboxOptions } from "./options/checkboxOptions";
import { getListedStringOptions } from "./options/listedStringOptions";
import { getSurveyTemplateOptions } from "./options/surveyTemplateOptions";
import { ISurveyTemplate, IUserLocationListItem, IUserDepartmentListItem } from "../../../types/resources";

const getUserAgeOptions = (options: string[]) => options.map(number => {
  return { value: number, text: `${number} ${Number(number) > 1 ? 'days' : 'day'} old` }
});

export const useUserListFilters = (
  departmentOptions: IUserDepartmentListItem[],
  locationOptions: IUserLocationListItem[],
  surveyTemplates: ISurveyTemplate[],
  userAges: string[]
) => useMemo<IFilterConfig[]>(() => [
  {
    label: 'Due For Assessment',
    key: 'assessmentDue',
    type: 'select',
    options: getSurveyTemplateOptions(surveyTemplates),
  },
  {
    label: 'User creation date',
    key: 'minimumUserAge',
    type: 'select',
    options: getUserAgeOptions(userAges),
  },
  {
    label: 'User Name Search',
    key: 'userNameLike',
    type: 'text',
  },
  {
    label: 'Location',
    key: 'location',
    type: 'multiSelect',
    options: getListedStringOptions(locationOptions),
  },
  {
    label: 'Department',
    key: 'department',
    type: 'multiSelect',
    options: getListedStringOptions(departmentOptions),
  },
  {
    label: 'Job Title Search',
    key: 'jobTitleLike',
    type: 'text',
  },
  {
    label: 'User Archived',
    key: 'archived',
    type: 'multiSelect',
    options: getCheckboxOptions('Archived', 'Non-Archived'),
  },
], [departmentOptions, locationOptions, surveyTemplates, userAges]);
