import React, { FunctionComponent, ReactElement } from "react";
import { isArray } from 'lodash';
import { IEmailDeliveryStatus } from "../../../types/resources";
import { FiltersProps } from "./types";
import { filterOptions, getSelectOptions } from "../../../common/Filters/utils";
import { FilterItem, Filters } from "../../../common/Filters/Filters";
import NameFilter from "../../../common/Filters/NameFilter";
import ClearAllFilters from "../../../common/Filters/ClearAllFilters";
import AssessmentDueFilter from "../../../common/Filters/AssessmentDueFilter";
import DepartmentFilter from "../../../common/Filters/DepartmentFilter";
import LocationFilter from "../../../common/Filters/LocationFilter";
import ArchivedFilter from "../../../common/Filters/ArchivedFilter";
import InvalidEmailsFilter from "../../../common/Filters/InvalidEmailsFilter";
import JobTitleFilter from "../../../common/Filters/JobTitleFilter";
import UserAgeFilter from "../../../common/Filters/UserAgeFilter";

const UserFilters: FunctionComponent<FiltersProps> = ({ config, onChange, onClear, filters }): ReactElement => {
  const userNameLike = filters.current.userNameLike?.toString() || '';
  const jobTitle = filters.current.jobTitleLike?.toString() || '';
  const assessmentOptions = getSelectOptions(config, 'assessmentDue');
  const departmentOptions = getSelectOptions(config, 'department');
  const locationOptions = getSelectOptions(config, 'location');
  const userAgeOptions = getSelectOptions(config, 'minimumUserAge')
  const archived = isArray(filters.current.archived) && filters.current.archived.includes(true);
  const onlyInvalidEmails = isArray(filters.current.emailDeliveryStatus) && (filters.current.emailDeliveryStatus as IEmailDeliveryStatus[]).includes('hard_bouncing');
  const assessmentDue = filterOptions(assessmentOptions, filters.current.assessmentDue);
  const department = filterOptions(departmentOptions, filters.current.department);
  const location = filterOptions(locationOptions, filters.current.location);
  const userAge = filterOptions(userAgeOptions, filters.current.minimumUserAge)

  return (
    <Filters columns={5}>
      <FilterItem>
        <NameFilter filterKey="userNameLike" value={userNameLike} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <AssessmentDueFilter filterKey="assessmentDue" value={assessmentDue} options={assessmentOptions} onChange={onChange}/>
      </FilterItem>

      <FilterItem>
        <DepartmentFilter filterKey="department" value={department} options={departmentOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <UserAgeFilter filterKey="minimumUserAge" value={userAge} options={userAgeOptions} onChange={onChange}/>
      </FilterItem>


      <FilterItem>
        <JobTitleFilter filterKey="jobTitleLike" value={jobTitle} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <ArchivedFilter filterKey="archived" value={archived} onChange={onChange}/>
      </FilterItem>

      <FilterItem>
        <LocationFilter filterKey="location" value={location} options={locationOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <InvalidEmailsFilter filterKey="emailDeliveryStatus" value={onlyInvalidEmails} onChange={onChange} />
      </FilterItem>

      <FilterItem />      
      <FilterItem>
        <ClearAllFilters onClear={onClear} />
      </FilterItem>
    </Filters>
  );
};

export default UserFilters;
